import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../Redux/RootReduxer';
import PanoramaView from '../Components/PanoramaView/PanoramaView';
import { Typography, WithStyles, withStyles, useTheme, Button } from '@material-ui/core';
import { styles } from './HomeStyles';
import ContentTitle from '../Components/ContentTitle/ContentTitle';
import { SportsEsportsOutlined, SportsEsportsRounded, SportsEsportsTwoTone, SportsEsportsSharp, GroupAddSharp, EventSharp, HighQualitySharp, EmojiEventsSharp, SettingsApplicationsSharp } from '@material-ui/icons';
import MailSignup from '../Components/MailSignup/MailSignup';

const logoWatermark = require('../../Media/LogoWatermark.png');
const logoFullLight = require('../../Media/black.png');
const logoFullDark = require('../../Media/white.png');

const ribbonBlack = require('../../Media/RibbonBlack.png');
const ribbonWhite = require('../../Media/RibbonWhite.png');

const promo = require('../../Media/bannerNoLogo.png');

const mapState = (state: RootState) => ({
    isDarkTheme: state.Main.isDarkTheme
})

const mapDispatch = {
    
}

const connector = connect(mapState, mapDispatch)


interface Props extends WithStyles<typeof styles>, ConnectedProps<typeof connector>{

}

const Home = (props: Props) => {
    const theme = useTheme();

    // FNE Logo
    const FNELogo = () => (<div className={props.classes.logoContent}>
        <img 
            className={props.classes.logo}
            src={(props.isDarkTheme ? logoFullDark : logoFullLight)}
        />
    </div>);

    // Project FNZ
    const ProjectFNZ = () => (<div className={props.classes.logoContent}>
        <div>
            <div style={{
                display: 'block', 
                backgroundColor: props.isDarkTheme ? 'rgba(1, 1, 1, 0.75)' : 'rgba(255, 255, 255, 0.75)', 
                padding: 16, 
                paddingBottom: 4, 
                paddingTop: 48, 
                borderRadius: 4
            }}>
                <MailSignup />
            </div>
        </div>
         
        
        {/*<a className={props.classes.link} target="_blank" href="http://www.farnorthentertainment.com/signup/">
            <Button color="primary" className={props.classes.projFNZ}>
                Sign Up
            </Button>
            <p />
            <div style={{display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.4)', padding: 16, paddingBottom: 4, paddingTop: 0, borderRadius: 4}}>
                <div>
                    <Typography variant="h2" style={{marginTop: 16}}>
                        Sign up for our newsletter today!
                    </Typography>
                </div>
            </div>
    </a>*/}
        {/*<div className={props.classes.link}>
        <Typography variant="h2" style={{marginTop: 16, marginBottom: 50, fontWeight: 1000, textShadow: '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'}}>
            Wishlist Hordebreak on Steam NOW!
        </Typography>
        <iframe src="https://store.steampowered.com/widget/1198920/" frameBorder="0" width="646" height="190"></iframe>
    </div>*/}
    </div>);

    // What We Do
    const WhatWeDo = () => (<div className={props.classes.whatWeDoContent}>
        <ContentTitle title="What We Do" />
        <div className={props.classes.contentWrapper}>
            <div className={props.classes.contentSection}>
                <SportsEsportsSharp 
                    className={props.classes.sectionIcon}
                />
                <Typography variant="h3">Fun</Typography>
                <Typography variant="body1">
                    Our big passion and primary goal is to make the best digital entertainment, which give rise to
                    communities where friendships are born and boredom is but a myth.
                </Typography>
            </div>

            <div className={props.classes.contentSection}>
                <EmojiEventsSharp 
                    className={props.classes.sectionIcon}
                />
                <Typography variant="h3">Quality</Typography>
                <Typography variant="body1">
                    Quality is always a top priority for us. Whenever code is written, characters are
                    born or worlds emerge, it is all done by the hands of passionate individuals who care deeply for their art and craft.
                </Typography>
            </div>

            <div className={props.classes.contentSection}>
                <SettingsApplicationsSharp
                    className={props.classes.sectionIcon}
                />
                <Typography variant="h3">Technology</Typography>
                <Typography variant="body1">
                    We bring strong technical competence within game development across a multitude of platforms,
                    always striving to adapt and bring the latest technology to our customers.
                </Typography>
            </div>
        </div>
    </div>);

    return <>
        <PanoramaView 
            backgroundImage={logoWatermark}
            backgroundSize={'repeat'}
        >
            <div style={{
                width: '100vw', 
                opacity: 0.3, 
                marginBottom: 40, 
                height: 40, 
                backgroundRepeat: 'repeat-x',  
                backgroundImage: props.isDarkTheme ? "url(" + ribbonBlack + ")" : "url(" + ribbonWhite + ")"
            }} />
            <FNELogo />
            <div style={{
                width: '100vw', 
                opacity: 0.3, 
                marginTop: 40, 
                height: 40, 
                backgroundRepeat: 'repeat-x',  
                backgroundImage: props.isDarkTheme ? "url(" + ribbonBlack + ")" : "url(" + ribbonWhite + ")"
            }} />
        </PanoramaView>
        <PanoramaView
            backgroundImage={promo}
            parallax
        >
            <ProjectFNZ />
        </PanoramaView>
        <PanoramaView 
            backgroundColor={theme.fne_palette.background}
            backgroundSize={'repeat'}
        >
            <WhatWeDo />
        </PanoramaView>
    </>
}


export default withStyles(styles)(connector(Home));