import { createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { mobileQuery } from '../../Constants/MediaQuery';
import { FullscreenExit } from '@material-ui/icons';

export const styles = (theme: Theme) => {
  return createStyles({
    presskitMain: {
      display: 'flex',
      margin: 'auto',
      maxWidth: 970,
      overflowX: 'visible',
      transition: '0.3s',
      paddingTop: 10,
      flexDirection: 'row',
      alignItems: 'stretch',
    },
    columnWrapper: {
      display: 'flex',
      flexDirection: 'row',
      color: theme.palette.text.primary,
      flexGrow: 1,
      [mobileQuery(theme)]: {
        flexDirection: 'column',
      },
      flexWrap: 'wrap',
    },
    leftColumn: {
      flex: 0.4,
      flexGrow: 0.4,
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      margin: 10,
      textAlign: 'left',
      flexBasis: 0.4,
    },
    rightColumn: {
      minWidth: 400,
      flex: 0.6,
      flexBasis: 0.6,
      flexGrow: 0.6,
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      textAlign: 'left',
      margin: 10,
    },
    oneColumn: {
      flex: 1,
      flexGrow: 1,
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      textAlign: 'left',
      margin: 10,
      flexBasis: '100%',
    },
    pressKitSection: {
      padding: 40,
      backgroundColor: theme.fne_palette.barBackground,
      marginBottom: 20,
    },
    pressKitHeader: {},
    pressKitSubHeader: {
      marginBottom: 4,
    },
    link: {
      textDecoration: 'none',
      color: theme.fne_palette.fne_red,
    },
    imageParent: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      margiunLeft: 'auto',
      [mobileQuery(theme)]: {
        justifyContent: 'center',
      },
    },
  });
};
