import React, { FunctionComponent, useEffect, useState, createRef } from 'react';
import { WithStyles, withStyles, useTheme, useMediaQuery } from '@material-ui/core';
import { styles } from './PanoramaViewStyles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { mobileQuery } from '../../../Constants/MediaQuery';

interface Props extends WithStyles<typeof styles> {
    backgroundImage?: any;
    backgroundColor?: CSSProperties['color'];
    backgroundSize?: CSSProperties['backgroundSize'];
    parallax?: boolean;
    heightInVh?: number;
}

export const PanoramaView: FunctionComponent<Props>  = (props) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(mobileQuery(theme));
    
    const [y, setY] = useState<number>(0);

    const parallaxRef = createRef<HTMLDivElement>();

    function scrollyBoi(){
        
        if(parallaxRef != null && parallaxRef.current != null){
            if(isMobile){
                parallaxRef.current.style.backgroundPositionY = 0 + 'px';
                return;
            }
            
            if(props.parallax){
                parallaxRef.current.style.backgroundPositionY = -200 + (window.scrollY * 0.3) + 'px';
            }
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', () => scrollyBoi());

        return () => {
            window.removeEventListener("scroll", scrollyBoi);
        }
    })

    return <div 
        className={props.classes.panoramaMain}
        style={{
            backgroundImage: props.backgroundImage ? "url(" + props.backgroundImage + ")" : "undefined",
            backgroundColor: props.backgroundColor ? props.backgroundColor : theme.fne_palette.paper,
            backgroundSize: props.backgroundSize ? props.backgroundSize : "cover",
            minHeight: props.heightInVh ? props.heightInVh + 'vh' : '60vh'
        }}
        ref={parallaxRef}
    >
        <div className={props.classes.panoramaContentWrapper}>
            {props.children}
        </div>
    </div>
}

export default withStyles(styles)(PanoramaView);