import React from 'react';
import { NavLink } from 'react-router-dom';
import {styles} from '../NavBarStyles';
import { withStyles, WithStyles, Typography, Button } from '@material-ui/core';


interface Props extends WithStyles<typeof styles> {
    title: string;
    path: string;
    onClick?: () => void;
}

export const NavBarButton = (props: Props) => {
    console.log("NEW PATH", props.path)
    if(props.path.indexOf("https://") >= 0){
        return (
        <a style={{textDecoration: 'none'}} href="https://www.Hordebreak.com">
            <Button className={props.classes.button}>
                <Typography variant={'h1'}>{props.title}</Typography>
            </Button>
        </a>)
    }else{
        return (<NavLink
            exact
            replace
            to={props.path}
            className={props.classes.buttonText}
            activeClassName={props.classes.buttonText}
            onClick={props.onClick}
        >
            <Button className={props.classes.button}>
                <Typography variant={'h1'}>{props.title}</Typography>
            </Button>
        </NavLink>)
    }
   
};

export default withStyles(styles)(NavBarButton);