import { createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { mobileQuery } from '../../Constants/MediaQuery';
import { FullscreenExit } from '@material-ui/icons';

export const styles = (theme: Theme) => {
    return createStyles({
        logoContent: {
            height: '100%',
            display: 'flex',
            verticalAlign: 'middle',
        },
        logo: {
            display: 'inline-flex',
            maxHeight: '60vh',
            transition: '0.3s',
            [mobileQuery(theme)]: {
                maxWidth: '100vw',
                height: 'auto',
                maxHeight: '30vh',
            }
        },
        whatWeDoContent: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            textAlign: 'center',
        },
        contentWrapper: {
            display: 'flex',
            flexDirection: 'row',
            maxWidth: '100vw',
            flexWrap: 'wrap',
            justifyContent: 'center'
        },
        contentSection: {
            width: '20vw',
            marginLeft: '2vw',
            marginRight: '2vw',
            color: theme.palette.text.primary,
            [mobileQuery(theme)]: {
                width: '80vw',
                height: 'auto',
                marginBottom: 32
            }
        },
        sectionIcon: {
            color: theme.palette.text.primary,
            transition: '0.3s',
            width: 60,
            height: 60
        },
        projFNZ: {
            backgroundColor: theme.palette.grey[900],
            color: 'white',
            fontSize: 32,
            padding: 12,
            paddingLeft: 40,
            paddingRight: 40,
            fontFamily: 'Roboto',
            '&:hover': {
                backgroundColor: theme.fne_palette.fne_red
            },
            [mobileQuery(theme)]: {
                fontSize: 18,
            }
        },
        link: {
            textDecoration: 'none',
            color: '#dedede',
        },
        ribbon: {
            width: '100%',
            hreight: 200
        }
    })
};