import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import {styles} from '../NavBarStyles';
import { withStyles, WithStyles, Typography, Button, Icon, Menu, MenuItem } from '@material-ui/core';
import {Menu as MenuIcon} from '@material-ui/icons';
import { Links } from '../NavBar';
import NavBarButton from './NavBarButton';


interface Props extends WithStyles<typeof styles> {
    
}

export const NavBarBurger = (props: Props) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [burgerAnchor, setBurgerAnchor] = useState<HTMLElement>();

    return <div>
        <Button 
            aria-controls="simple-menu" 
            aria-haspopup="true" 
            onClick={(event: React.MouseEvent<{}>) => {
                setMenuOpen(!menuOpen);
                setBurgerAnchor(event.target as HTMLElement);
            }}
        >
            <MenuIcon/>
        </Button>
        <Menu 
            open={menuOpen}
            anchorEl={burgerAnchor}
        >
            {Links.map((linkData) =>  
                <NavBarButton 
                    {...linkData} 
                    onClick={() => setMenuOpen(false)}
                />
            )}
        </Menu>
    </div>
}

export default withStyles(styles)(NavBarBurger);