import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../Redux/RootReduxer';
import PanoramaView from '../Components/PanoramaView/PanoramaView';
import { Typography, WithStyles, withStyles, useTheme, Button, useMediaQuery } from '@material-ui/core';
import { styles } from './HordebreakStyles';
import ContentTitle from '../Components/ContentTitle/ContentTitle';
import { SportsEsportsOutlined, SportsEsportsRounded, SportsEsportsTwoTone, SportsEsportsSharp, GroupAddSharp, EventSharp, HighQualitySharp, EmojiEventsSharp, SettingsApplicationsSharp } from '@material-ui/icons';
import MailSignup from '../Components/MailSignup/MailSignup';
import ImageLink from '../Components/ImageLink/ImageLink';
import { mobileQuery } from '../../Constants/MediaQuery';
import Discord from '../Components/Discord/Discord';

const promo = require('../../Media/Logo3.png');

const mapState = (state: RootState) => ({
    isDarkTheme: state.Main.isDarkTheme
})

const banner = require('../../Media/bannerShort.png');

const fnzPromotn = require('../../Media/Logo3tn.png');
const fnz0tn = require('../../Media/Hordebreak0tn.png');
const fnz1tn = require('../../Media/Hordebreak1tn.png');
const fnz2tn = require('../../Media/Hordebreak2tn.png');
const fnz3tn = require('../../Media/Hordebreak3tn.png');
const fnz4tn = require('../../Media/Hordebreak4tn.png');


const fnzPromo = require('../../Media/Logo3.png');
const fnz0 = require('../../Media/Hordebreak0.png');
const fnz1 = require('../../Media/Hordebreak1.png');
const fnz2 = require('../../Media/Hordebreak2.png');
const fnz3 = require('../../Media/Hordebreak3.png');
const fnz4 = require('../../Media/Hordebreak4.png');


const mapDispatch = {
    
}

const connector = connect(mapState, mapDispatch)


interface Props extends WithStyles<typeof styles>, ConnectedProps<typeof connector>{

}

const Hordebreak = (props: Props) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(mobileQuery(theme));

    return <>
        <div style={{display: 'flex', margin:'auto'}}>
            <img style={{maxWidth: isMobile ? '110vw' : '70vw', margin: 'auto'}} src={banner} />
        </div>
       
       
        <div className={props.classes.gameDescWrapper}>
            <Typography variant="h3">Hordebreak is a post-apocalyptic, cyber-punk themed survival game, featuring savage mutant hordes of thousands in an open world sandbox experience.</Typography>
        </div>

        <div style={{
            paddingTop: isMobile ? 48 : 48, 
            backgroundColor: theme.fne_palette.paper,
            borderStyle: 'solid',
            borderColor: theme.fne_palette.backgroundTransparent2,
            borderWidth: 4,
            marginTop: 32,
        }}>
            <MailSignup />
        </div>

        <div style={{display: 'flex', margin: 'auto', marginTop: 16, width: isMobile ? 400 : 600, justifyContent: 'center', alignItems: 'center'}}>
            <Discord />
        </div>
           


        <div style={{
            display: 'flex', 
            flexDirection: 'column', 
            justifyContent: 'center',
            textAlign: 'center',
            alignItems: 'center',
            marginTop: 24
        }}
        >
           <div className={props.classes.featuresWrapper}>
                <ContentTitle
                    title="Features"
                />
           </div>
           <div className={props.classes.featureHeaderWrapper}>
                <Typography variant="h2">SURVIVAL</Typography>
           </div>
           <div className={props.classes.featureBodyWrapper}>
                <Typography variant="body1">Explore an unforgiving world filled with natural hazards, inhospitable weather and countless enemies. Plunder abandoned sites, craft weapons of extermination and claim your spot in a world bent on your destruction.</Typography>
           </div>
           <div className={props.classes.featureHeaderWrapper}>
                <Typography variant="h2">BASE BUILDING</Typography>
           </div>
           <div className={props.classes.featureBodyWrapper}>
                <Typography variant="body1">Design and construct intricate fortresses providing shelter, resources and tools for your endeavours. Supply it with energy, water and other utilities which are critical to conducting your operations. Establish destructive defensive parimeters, protecting what is rightfully yours.</Typography>
           </div>
           <div className={props.classes.featureHeaderWrapper}>
                <Typography variant="h2">CO-OP MULTIPLAYER</Typography>
           </div>
           <div className={props.classes.featureBodyWrapper}>
                <Typography variant="body1">Band up with your friends, and explore this unforgiving world together. travel by foot, or by vehicles of war. Always stick together, or leave the weak behind; the choice is yours.</Typography>
           </div>
           <div className={props.classes.featureHeaderWrapper}>
                <Typography variant="h2">MODDING SUPPORT</Typography>
           </div>
           <div className={props.classes.featureBodyWrapper}>
                <Typography variant="body1">Use the level editor or modify the exposed content XML files in order to create original designs which can be shared with others. Items, world features and weapons are all examples of game content which will be fully customizable by you and other players.</Typography>
           </div>
        </div>
        <PanoramaView 
            backgroundColor={theme.fne_palette.background}
            heightInVh={25}
        >
            <div className={props.classes.featuresWrapper}>
                <ContentTitle
                    title="Gallery"
                />
            </div>
            <div className={props.classes.imageParent}>
                <ImageLink thumbnail={fnzPromotn} src={fnzPromo} title="Promo" />
                <ImageLink thumbnail={fnz0tn} src={fnz0} title="Sunset" />
                <ImageLink thumbnail={fnz1tn} src={fnz1} title="Backlit Horde" />
                <ImageLink thumbnail={fnz2tn} src={fnz2} title="Budroot Patch" />
                <ImageLink thumbnail={fnz3tn} src={fnz3} title="Warehouse" />
                <ImageLink thumbnail={fnz4tn} src={fnz4} title="Arctic Border" />
            </div>
        </PanoramaView>
        
    </>
}


export default withStyles(styles)(connector(Hordebreak));