import { createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { mobileQuery } from '../../Constants/MediaQuery';
import { FullscreenExit } from '@material-ui/icons';

export const styles = (theme: Theme) => {
    return createStyles({
        blogMain: {
            display: 'flex',
            margin: 'auto',
            maxWidth: 970,
            overflowX: 'visible',
            transition: '0.3s',
            flexDirection: 'column',
            alignItems: 'stretch',
          },
          postEntryLink: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            margin: 10,
            backgroundColor: theme.fne_palette.barBackground,
            color: theme.fne_palette.fne_red,
            textDecoration: 'none',
          },
          postEntry: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            margin: 10,
          },
    })
};