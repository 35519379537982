import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../Redux/RootReduxer';
import {styles} from './FooterStyles';
import { withStyles, WithStyles, IconButton, SvgIcon, Typography } from '@material-ui/core';
import { Routes } from '../../Constants/Routes';
import classes from '*.module.css';
import {toggleTheme} from '../Redux/MainActions';
import { facebookIcon, youtubeIcon, twitterIcon, instagramIcon, linkedinIcon } from './svgIcons';

const mapState = (state: RootState) => ({
    isDarkTheme: state.Main.isDarkTheme
})

const mapDispatch = {
    
}

const connector = connect(mapState, mapDispatch)

interface Props extends WithStyles<typeof styles>, ConnectedProps<typeof connector> {
    
}

const iconStyle = {
    '&:hover': {
        backgroundColor: "black",
        color: "black",
        fill: 'none'
    },
}

const Footer = (props: Props) => {
    return <div className={props.classes.wrapper}>
        <div className={props.classes.contactBox}>
            <Typography variant="h1" className={props.classes.contactTitle}>
                Far North Entertainment
            </Typography>
            <Typography variant="body2" className={props.classes.contactText}>
                Stigbergsliden 5, 414 63, Gothenburg, Sweden
            </Typography>
            <Typography variant="body2" className={props.classes.contactText}>
                contact@farnorthentertainment.com
            </Typography>
        </div>
        <div className={props.classes.socialMediaBox} >
            <a target='_blank' href='https://www.facebook.com/farnorthentertainment/'>
                <div className={props.classes.socialMediaButtonWrapper}>
                    <svg viewBox="-0 -0 300 300">
                        {facebookIcon}
                    </svg>
                </div>
            </a>
            <a target='_blank' href='https://twitter.com/FarNorthEnt'>
                <div className={props.classes.socialMediaButtonWrapper}>
                    <svg viewBox="-0 -0 300 300">
                        {twitterIcon}
                    </svg>
                </div>
            </a>
            <a target='_blank' href='https://www.youtube.com/channel/UCjOGyrkIv7doq1Mx2RaqvjA'>
                <div className={props.classes.socialMediaButtonWrapper}>
                    <svg viewBox="-0 -0 90 90">
                        {youtubeIcon}
                    </svg>
                </div>
            </a>
            <a target='_blank' href='https://www.instagram.com/far_north_entertainment/?hl=en'>
                <div className={props.classes.socialMediaButtonWrapper}>
                    <svg viewBox="-0 -0 170 170">
                        {instagramIcon}
                    </svg>
                </div>
            </a>
            <a target='_blank' href='https://www.linkedin.com/organization-guest/company/farnorthentertainment'>
                <div className={props.classes.socialMediaButtonWrapper}>
                    <svg viewBox="-0 -0 300 300">
                        {linkedinIcon}
                    </svg>
                </div>
            </a>
        </div>
    </div>
}



export default withStyles(styles)(connector(Footer));