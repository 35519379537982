import { createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { mobileQuery } from '../../../Constants/MediaQuery';

export const styles = (theme: Theme) => {
    return createStyles({
        TitleText: {
            color: theme.palette.text.secondary
        },
        DividerLine: {
            width: 200,
            borderBottomWidth: 1,
            borderBottom: 'solid',
            color: theme.palette.text.primary,
            marginBottom: 24
        }
    })
};