import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../Redux/RootReduxer';
import {
  WithStyles,
  withStyles,
  Typography,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import PanoramaView from '../../Components/PanoramaView/PanoramaView';
import { styles } from '../PresskitStyles';
import { mobileQuery } from '../../../Constants/MediaQuery';
import ContentTitle from '../../Components/ContentTitle/ContentTitle';
import { NavLink, Route, Switch, useRouteMatch } from 'react-router-dom';
import { Routes } from '../../../Constants/Routes';
import ImageLink from '../../Components/ImageLink/ImageLink';
import { DarkTheme, LightTheme } from '../../../Theme/Themes';

const logo0 = require('../../../Media/Logo0.png');
const logo1 = require('../../../Media/Logo1.png');
const logo2 = require('../../../Media/Logo2.png');
const logo3 = require('../../../Media/Logo3.png');
const fnz0 = require('../../../Media/Hordebreak0.png');
const fnz1 = require('../../../Media/Hordebreak1.png');
const fnz2 = require('../../../Media/Hordebreak2.png');
const fnz3 = require('../../../Media/Hordebreak3.png');
const fnz4 = require('../../../Media/Hordebreak4.png');

const mapState = (state: RootState) => ({
  isDarkMode: state.Main.isDarkTheme,
});

const mapDispatch = {};

const connector = connect(mapState, mapDispatch);

interface Props
  extends WithStyles<typeof styles>,
    ConnectedProps<typeof connector> {}

const Presskit = (props: Props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(mobileQuery(theme));

  return (
    <div className={props.classes.presskitMain}>
      <PanoramaView
        heightInVh={20}
        parallax
        backgroundColor={
          props.isDarkMode ? 'rgba(1, 1, 1, 0)' : 'rgba(1, 1, 1, 0)'
        }>
          <PanoramaView 
             heightInVh={60}
             backgroundImage={logo3}
             backgroundSize={'cover'}
             backgroundColor={
               props.isDarkMode ? 'rgba(1, 1, 1, 0)' : 'rgba(1, 1, 1, 0)'
             }
          />
        <div className={props.classes.columnWrapper}>
          <div className={props.classes.leftColumn}>
            <div className={props.classes.pressKitSection}>
              <div className={props.classes.pressKitHeader}>
                <Typography variant="h3">Fact Sheet</Typography>
              </div>

              <div className={props.classes.pressKitSubHeader}>
                <Typography variant="h1">Developer</Typography>
              </div>
              <Typography variant="body2">
                Far North Entertainment <br />
                Based in Gothenburg, Sweden
              </Typography>

              <br />

              <div className={props.classes.pressKitSubHeader}>
                <Typography variant="h1">Platform</Typography>
              </div>
              <Typography variant="body2">PC</Typography>

              <br />

              <div className={props.classes.pressKitSubHeader}>
                <Typography variant="h1">Website</Typography>
              </div>
              <Typography variant="body2">
                <a
                  href="http://farnorthentertainment.com/signup/"
                  className={props.classes.link}>
                  http://farnorthentertainment.com/signup/
                </a>
              </Typography>

              <br />

              <div className={props.classes.pressKitSubHeader}>
                <Typography variant="h1">Press / Business contact</Typography>
              </div>

              <Typography variant="body1">
                <a
                  href="mailto: contact@farnorthentertainment.com"
                  className={props.classes.link}>
                  contact@farnorthentertainment.com
                </a>
              </Typography>

              <br />

              <div className={props.classes.pressKitSubHeader}>
                <Typography variant="h1">Release date</Typography>
              </div>
              <Typography variant="body2">Not yet determined</Typography>

              <br />

              <div className={props.classes.pressKitSubHeader}>
                <Typography variant="h1">Regular price</Typography>
              </div>
              <Typography variant="body2">Not yet determined</Typography>

              <br />
            </div>
            <div className={props.classes.pressKitSection}>
              <div className={props.classes.pressKitHeader}>
                <Typography variant="h3">Video</Typography>
              </div>

              <div className={props.classes.pressKitSubHeader}>
                <Typography variant="h1">Youtube</Typography>
              </div>
              <a
                href="https://www.youtube.com/channel/UCjOGyrkIv7doq1Mx2RaqvjA"
                target="_blank"
                className={props.classes.link}>
                Visit our youtube channel
              </a>
            </div>
          </div>

          <div className={props.classes.rightColumn}>
            <div className={props.classes.pressKitSection}>
              <div className={props.classes.pressKitHeader}>
                <Typography variant="h3">Description</Typography>
              </div>

              <Typography variant="body2">
                Hordebreak is a post-apocalyptic, third-person survival game, featuring
                hordes of mutants in an open world sandbox
                experience.
              </Typography>

              <br />

              <div className={props.classes.pressKitHeader}>
                <Typography variant="h3">Features</Typography>
              </div>

              <Typography variant="h5">True carnage</Typography>
              <Typography variant="body2">
                Fight savage mutant hordes using hardware tools, bullets, flames and
                explosions. Only one thing matters: Do not get
                overwhelmed.
              </Typography>

              <br />

              <Typography variant="h5">Survival</Typography>
              <Typography variant="body2">
                Explore an alien unforgiving world filled with abandoned cities,
                infested facilities and countless enemies. Plunder sites, craft
                weapons of extermination and claim your spot in a world bent on
                your destruction.
              </Typography>

              <br />

              <Typography variant="h5">Base building</Typography>
              <Typography variant="body2">
                Design and construct intricate fortresses providing shelter,
                resources and tools for your endeavours. Supply it with energy,
                water and other utilities which are critical to conducting your
                operations. Establish destructive defensive parimeters,
                protecting what is rightfully yours.
              </Typography>

              <br />

              <Typography variant="h5">Multiplayer</Typography>
              <Typography variant="body2">
                Band up with your friends, and explore the world together.
                Always stick together, or leave the weak behind; the choice is
                yours.
              </Typography>

              <br />

              <Typography variant="h5">Modding support</Typography>
              <Typography variant="body2">
                Use the level editor or modify the exposed content XML files in
                order to create original designs which can be shared with
                others. Items, world features and weapons are all examples of
                game content which will be fully customizable by you and other
                players.
              </Typography>
            </div>
          </div>
          <div className={props.classes.oneColumn}>
            <div className={props.classes.pressKitSection}>
              <div className={props.classes.pressKitHeader}>
                <Typography variant="h3">Images</Typography>
              </div>

              <Typography variant="h5">Disclaimer</Typography>
              <Typography variant="body2">
                The screen dumps were taken during different phases of
                development, and might not accurately depict the game in its
                final, or even current state.
              </Typography>

              <br />

              <div className={props.classes.imageParent}>
                <ImageLink src={fnz0} title="Sunset" />
                <ImageLink src={fnz1} title="Backlit Horde" />
                <ImageLink src={fnz2} title="Budroot Patch" />
                <ImageLink src={fnz3} title="Warehouse" />
                <ImageLink src={fnz4} title="Arctic Border" />
                <ImageLink src={logo0} title="Logo Standalone" />
                <ImageLink src={logo1} title="Logo Banner" />
                <ImageLink src={logo3} title="Logo Banner 2" />
                <ImageLink src={logo2} title="Logo Poster Small" />
              </div>
            </div>
          </div>
        </div>
      </PanoramaView>
    </div>
  );
};

export default withStyles(styles)(connector(Presskit));
