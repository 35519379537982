import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../Redux/RootReduxer';
import {
  WithStyles,
  withStyles,
  Typography,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import PanoramaView from '../../Components/PanoramaView/PanoramaView';
import { styles } from '../PresskitStyles';
import { mobileQuery } from '../../../Constants/MediaQuery';
import ContentTitle from '../../Components/ContentTitle/ContentTitle';
import { NavLink, Route, Switch, useRouteMatch } from 'react-router-dom';
import { Routes } from '../../../Constants/Routes';
import ImageLink from '../../Components/ImageLink/ImageLink';
import { DarkTheme, LightTheme } from '../../../Theme/Themes';

const logoFullLight = require('../../../Media/black.png');
const logoFullDark = require('../../../Media/white.png');

const mapState = (state: RootState) => ({
  isDarkMode: state.Main.isDarkTheme,
});

const mapDispatch = {};

const connector = connect(mapState, mapDispatch);

interface Props
  extends WithStyles<typeof styles>,
    ConnectedProps<typeof connector> {}

const teamBannerDark = require('../../../Media/teambannerdark.png');
const teamBannerLight = require('../../../Media/teambannerlight.png');

const rw_idle = require('../../../Media/rw_idle.jpg');
const rw_action = require('../../../Media/rw_action.jpg');
const kb_idle = require('../../../Media/kb_idle.jpg');
const kb_action = require('../../../Media/kb_action.jpg');
const ae_idle = require('../../../Media/ae_idle.jpg');
const ae_action = require('../../../Media/ae_action.jpg');
const jm_idle = require('../../../Media/jm_idle.jpg');
const jm_action = require('../../../Media/jm_action.jpg');
const se_idle = require('../../../Media/simon_idle.jpg');
const se_action = require('../../../Media/simon_action.jpg');

const Presskit = (props: Props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(mobileQuery(theme));

  return (
    <div className={props.classes.presskitMain}>
      <PanoramaView
        heightInVh={20}
        parallax
        backgroundColor={
          props.isDarkMode ? 'rgba(1, 1, 1, 0)' : 'rgba(1, 1, 1, 0)'
        }>
        <ContentTitle title="Press kit" />
        <div className={props.classes.columnWrapper}>
          <div className={props.classes.leftColumn}>
            <div className={props.classes.pressKitSection}>
              <div className={props.classes.pressKitHeader}>
                <Typography variant="h3">Fact Sheet</Typography>
              </div>

              <div className={props.classes.pressKitSubHeader}>
                <Typography variant="h1">Developer</Typography>
              </div>
              <Typography variant="body2">
                Far North Entertainment <br />
                Based in Gothenburg, Sweden
              </Typography>

              <br />

              <div className={props.classes.pressKitSubHeader}>
                <Typography variant="h1">Founded</Typography>
              </div>
              <Typography variant="body2">May 28, 2016</Typography>

              <br />

              <div className={props.classes.pressKitSubHeader}>
                <Typography variant="h1">Website</Typography>
              </div>
              <Typography variant="body2">FarNorthEntertainment.com</Typography>

              <br />

              <div className={props.classes.pressKitSubHeader}>
                <Typography variant="h1">Press / Business contact</Typography>
              </div>

              <Typography variant="body1">
                <a
                  href="mailto: contact@farnorthentertainment.com"
                  className={props.classes.link}>
                  contact@farnorthentertainment.com
                </a>
              </Typography>
              <p />

              <div className={props.classes.pressKitSubHeader}>
                <Typography variant="h1">Address</Typography>
              </div>
              <Typography variant="body2">
                Stigbergsliden 5 <br />
                414 63 Gothenburg <br />
                Sweden
              </Typography>
            </div>

            <div className={props.classes.pressKitSection}>
              <div className={props.classes.pressKitHeader}>
                <Typography variant="h3">Game Kits</Typography>
              </div>

              <div className={props.classes.pressKitSubHeader}>
                <Typography variant="h1">
                  <NavLink
                    exact
                    replace
                    to={'/Presskit/Hordebreak'}
                    className={props.classes.link}>
                    Hordebreak press kit
                  </NavLink>
                </Typography>
              </div>
            </div>
          </div>

          <div className={props.classes.rightColumn}>
            <div className={props.classes.pressKitSection}>
              <div className={props.classes.pressKitHeader}>
                <Typography variant="h3">History</Typography>
              </div>

              <div className={props.classes.pressKitSubHeader}>
                <Typography variant="h1">The beginning</Typography>
              </div>
              <Typography variant="body2">
                Founded in 2016, Far North Entertainment began as a group in a
                bachelor exam project at Chalmers University of Technology.
                Realising that making games was our destiny, we took the name
                Stenkross Studios and tried to turn the project into a
                commercial product.
              </Typography>

              <br />

              <div className={props.classes.pressKitSubHeader}>
                <Typography variant="h1">Our first endeavor</Typography>
              </div>
              <Typography variant="body2">
                The project was eventually cancelled, but following a
                conversation with Oculus, another game was born from the ashes:
                Down to Dungeon. The game is a classic top-down perspective
                dungeon crawlers where you have to fight your way through groups
                of enemies in your quest for the highest glory on the
                leaderboards, and is available for Samsung Gear VR.
              </Typography>

              <br />

              <div className={props.classes.pressKitSubHeader}>
                <Typography variant="h1">The hordes are born</Typography>
              </div>
              <Typography variant="body2">
                Following the release of Down to Dungeon we decided to leave the
                mobile VR platform and focus on the PC platform. By utilizing
                the new DOTS package from Unity Technologies, we created a horde
                simulation system which allows for multiple players to take on
                hordes of thousands of individual enemies in real-time combat.
                <p />
                This horde simulation system is the core feature of our current
                project: Hordebreak. A survival action game
                featuring a unique mashup of a post-apocalyptic cyber punk setting
                and sci-fi mutants.
              </Typography>

              <br />

              <Typography variant="body1">
                <a
                  className={props.classes.link}
                  href="http://www.farnorthentertainment.com/signup/">
                  Hordebreak signup page
                </a>
              </Typography>

              <br />

              <div className={props.classes.pressKitSubHeader}>
                <Typography variant="h1">Where we stand</Typography>
              </div>
              <Typography variant="body2">
                We have recently resumed the development of Hordebreak,
                following a period of IT consultant work in order to raise funds
                for the upcoming year. The project has so far been entirely
                funded through such side quests, but our goal is to pave the way
                forward such that we will not stray from our ultimate passion
                ever again.
                <p />
                We will not be able to fulfill the journey on our own. Are you
                interested in the project and want to stay in touch? Sign up to
                our newsletter, join our Discord or send us an email; We would
                love to have you aboard!
              </Typography>
            </div>
          </div>
          <div className={props.classes.oneColumn}>
            <div className={props.classes.pressKitSection}>
              <div className={props.classes.pressKitHeader}>
                <Typography variant="h3">Images</Typography>
              </div>

              <div className={props.classes.imageParent}>
                <ImageLink
                  backgroundColor={LightTheme().fne_palette.paper}
                  src={logoFullLight}
                  title="Logo Light"
                />
                <ImageLink
                  backgroundColor={DarkTheme().fne_palette.paper}
                  src={logoFullDark}
                  title="Logo Dark"
                />
                <ImageLink src={jm_idle} title="Jimmy" />
                <ImageLink src={ae_idle} title="Anders" />
                <ImageLink src={se_idle} title="Simon" />
                <ImageLink src={kb_idle} title="Kevin" />
                <ImageLink src={rw_idle} title="Richard" />
              </div>
              <Typography variant="body2">
                For media related to our products, visit the game kits.
              </Typography>
            </div>
          </div>
        </div>
      </PanoramaView>
    </div>
  );
};

export default withStyles(styles)(connector(Presskit));
