import { createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { mobileQuery } from '../../Constants/MediaQuery';

export const styles = (theme: Theme) => {
    return createStyles({
        wrapper: {
            display: 'flex',
            flexDirection: 'row',
            maxWidth: '100vw',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: theme.fne_palette.barBackground,
            transition: '0.3s',
            borderTop: 'solid',
            borderTopWidth: 2,
            borderTopColor: theme.fne_palette.paper,
            borderBottom: 'solid',
            borderBottomWidth: 2,
            borderBottomColor: theme.fne_palette.paper,
            paddingTop: 24,
            paddingBottom: 24
        },
        contactBox: {
            width: '30vw',
            marginLeft: '2vw',
            marginRight: '2vw',
            color: theme.palette.text.primary,
            lineHeight: 100,
            [mobileQuery(theme)]: {
                width: 'auto',
                height: 'auto',
                marginBottom: 32
            }
        },
        contactTitle: {
            marginBottom: 10,
        },
        contactText: {
            marginBottom: 10,
        },
        socialMediaBox: {
            display: 'flex',
            width: '30vw',
            height: 100,
            marginLeft: '2vw',
            marginRight: '2vw',
            color: theme.palette.text.primary,
            justifyContent: 'center',
            paddingLeft: 14,
            paddingTop: 24,
            [mobileQuery(theme)]: {
                width: '80vw',
                height: 'auto',
                marginBottom: 32
            }
        },
        socialMediaButtonWrapper: {
            width: 30,
            height: 30,
            textAlign: 'center',
            fill: theme.palette.text.primary,
            transition: '0.3s',
            marginRight: 14,
            '&:hover': {
                fill: theme.fne_palette.fne_red
            },
        },
        
    })
};