import { createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { mobileQuery } from '../../Constants/MediaQuery';

export const styles = (theme: Theme) => {
    return createStyles({
        bar: {
            backgroundColor: theme.fne_palette.barBackground,
            transition: '0.3s',
            width: '100vw',
            flexDirection: 'row',
            display: 'flex',
            position: 'fixed',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            zIndex: 100,
            [mobileQuery(theme)]: {
                justifyContent: 'stretch'
            }
        },
        logoWrapper: {
            display: 'flex',
        },
        logo: {
            position: 'relative',
            width: 45,
            height: 45,
            padding: 10
        },
        buttonWrapper: {
            display: 'flex',
            flexDirection: 'row',
            transition: '0.3s',
            [mobileQuery(theme)]: {
                '&:focus': {
                    backgroundColor: theme.fne_palette.barBackground,
                    color: theme.fne_palette.fne_red,
                },
                marginLeft: 'auto',
            }
        },
        button: {
            backgroundColor: theme.fne_palette.barBackground,
            display: 'block',
            padding: 10,
            paddingLeft: 16,
            paddingRight: 16,
            color: theme.palette.text.primary,
            transition: '0.3s',
            textTransform: 'none',
            '&:hover': {
                backgroundColor: theme.fne_palette.paper,
                color: theme.fne_palette.fne_red,
            },
            [mobileQuery(theme)]: {
                width: '90vw',
                textAlign: 'center',
                borderBottom: 'solid',
                borderBottomColor: theme.fne_palette.paper
            }
        },
        buttonText: {
            transition: '0.3s',
            textDecoration: 'none',
        }
    })
};