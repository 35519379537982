import { createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { mobileQuery } from '../../../Constants/MediaQuery';

export const styles = (theme: Theme) => {
  return createStyles({
    imgParent: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      width: 180,
      maxWidth: 180,
      textAlign: 'left',
      backgroundColor: theme.fne_palette.background,
      margin: 10,
      marginTop: 0,
    },
    img: {
      objectFit: 'scale-down',
      width: 140,
      maxWidth: 140,
      backgroundColor: theme.fne_palette.paper,
      padding: 20,
    },
    imgTitle: {
      width: 155,
      maxWidth: 155,
      height: 20,
      marginLeft: 10,
      padding: 10,
      paddingLeft: 15,
      backgroundColor: theme.fne_palette.background,
      color: theme.palette.text.primary,
    },
  });
};
