import { createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';

export const styles = (theme: Theme) => {
    return createStyles({
        AppWrapper: {
           backgroundColor: theme.fne_palette.background,
           transition: '0.3s',
           minHeight: '100vh'
        },
        NavBarSpace: {
            display: 'block',
            height: 80
        },
        copyrightWrapper: {
            marginLeft: 'auto',
            textAlign: 'right',
            padding: 16
        },
        copyright:{
            fontSize: 10,
            position: 'relative',
            bottom: 0,
            right: 0,
            color: theme.palette.text.primary,
            opacity: 0.6
        },
        exitIntentWrapper: {
            display: 'flex',
            position: 'fixed',
            flexDirection: 'row',
            minWidth: '100vw',
            minHeight: '100vh',
            backgroundColor: theme.fne_palette.backgroundTransparent,
            top: 0,
            left: 0,
            justifyContent: 'center',
            alignItems: 'center',
            
        },
        exitIntentPopup: {
            display: 'flex',
            maxWidth: 600,
            padding: 32,
            backgroundColor: theme.fne_palette.paper,
            borderRadius: 16,
            borderColor: theme.fne_palette.fne_red,
            borderWidth: 2,
            borderStyle: 'solid',
            flexDirection: 'column',
            boxShadow: '10px 5px 5px rgba(0, 0, 0, .5)'
        },
        exitIntentHeader: {
            color: theme.palette.text.primary,
            textAlign: 'center'
        },
        exitIntentMainMessage: {
            color: theme.palette.text.primary,
            textAlign: 'center',
            marginTop: -12
        },
        exitIntentCloseAnchor: {
            position: 'absolute',
            width: 0,
            height: 0
        },
        exitIntentClose: {
            width: 32,
            height: 32,
            textAlign: 'center',
            verticalAlign: 'middle',
            backgroundColor: theme.fne_palette.paper,
            borderRadius: 16,
            borderColor: theme.fne_palette.fne_red,
            borderWidth: 2,
            fontSize: 22,
            borderStyle: 'solid',
            color: theme.palette.text.primary,
            position: 'relative',
            right: 50,
            bottom: 50,
            cursor: 'pointer'
        },
        exitIntentMailListWrapper: {
            marginTop: 16,
        }
    })
};