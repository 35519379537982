import { createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { mobileQuery } from '../../Constants/MediaQuery';
import { FullscreenExit } from '@material-ui/icons';

export const styles = (theme: Theme) => {
    return createStyles({
        contentSection: {
            width: '80vw',
            marginLeft: '2vw',
            marginRight: '2vw',
            color: theme.palette.text.primary,
            [mobileQuery(theme)]: {
                width: '100vw',
                height: 'auto',
                marginBottom: 32
            }
        },
        gameDescWrapper: {
            width: '60vw',
            margin: 'auto',
            fontSize: 24,
            fontFamily: 'Roboto',
            color: theme.palette.text.primary,
            textAlign: 'center',
            padding: 16,
            paddingTop: 32,
            paddingLeft: '5vw',
            paddingRight: '5vw',
            backgroundColor: theme.fne_palette.paper,
            [mobileQuery(theme)]: {
                width: '90vw',
                fontSize: 18,
                paddingBottom: 0,
            }
        },
        featuresWrapper: {
            padding: 0,
            margin: 24,
            [mobileQuery(theme)]: {
            
                padding: 0
            }
        },
        featureHeaderWrapper: {
            color: theme.fne_palette.fne_red,
        },
        featureBodyWrapper: {
            color: theme.palette.text.primary,
            maxWidth: '50vw',
            marginTop: 8,
            marginBottom: 32,
            [mobileQuery(theme)]: {
                maxWidth: '90vw',
            }
        },
        imageParent: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'flex-start',
            margiunLeft: 'auto',
            [mobileQuery(theme)]: {
                justifyContent: 'center',
            },
        },
    })
};