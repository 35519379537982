import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../Redux/RootReduxer';
import { WithStyles, withStyles, Typography, useTheme, useMediaQuery, Button } from '@material-ui/core';
import { styles } from './BlogStyles';
import { mobileQuery } from '../../Constants/MediaQuery';
import ReactMarkdown from 'react-markdown'

const mapState = (state: RootState) => ({
    isDarkMode: state.Main.isDarkTheme
})

const mapDispatch = {
    
}

const connector = connect(mapState, mapDispatch);

interface Props extends WithStyles<typeof styles>, ConnectedProps<typeof connector>{
    match: {
        params: {
            filter: string;
        }
    };
}

const BlogPost = (props : Props) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(mobileQuery(theme));

    const [markDown, setMarkDown] = useState<string | undefined | '404'>(undefined);

    useEffect(() => {
        fetch('/blogposts/' + props.match.params.filter + '/markdown.md'
        ,{
            headers : { 
              'Content-Type': 'application/text',
              'Accept': 'application/text'
             }
          })
        .then(r => r.ok ? r.text() : '404')
        .then(text => { setMarkDown(text)});
    }, [])

    return <>
        {markDown != '404' ? <div style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', width: isMobile ? '90vw' : '60vw', textAlign: 'justify', color: theme.palette.text.primary }}>
            {
            markDown != undefined ?
            <ReactMarkdown 
                source={markDown as any}
                allowDangerousHtml={true}
            />
            : <></>
            }
        </div>
        :
        <div style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', width: '40%'}}>
            <img src={"/404.jpg"} />
        </div>}
    </>
    
}

export default withStyles(styles)(connector(BlogPost));