import React, { FunctionComponent, useEffect, useState } from 'react';
import {toggleTheme} from '../Redux/MainActions';
import { connect, ConnectedProps } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import NavBar from '../NavBar/NavBar';
import Home from '../../Sections/Home/Home';
import AboutUs from '../../Sections/TheTeam/TheTeam';
import { ThemeProvider, Typography, useTheme, WithStyles, withStyles } from '@material-ui/core';
import {DarkTheme, LightTheme} from '../../Theme/Themes';
import { Routes } from '../../Constants/Routes';
import {styles} from './SiteBodyStyles';
import Footer from '../Footer/Footer';
import Presskit from '../../Sections/Presskit/PresskitHub';
import Blog from '../../Sections/Blog/Blog';
import BlogPost from '../../Sections/Blog/BlogPost';
import Hordebreak from '../../Sections/Hordebreak/Hordebreak';
import { RootState } from '../../Redux/RootReduxer';
import MailSignup from '../../Sections/Components/MailSignup/MailSignup';
import Discord from '../../Sections/Components/Discord/Discord';

const mapState = (state: RootState) => ({
    isDarkTheme: state.Main.isDarkTheme
})

const mapDispatch = {
    
}

const connector = connect(mapState, mapDispatch)

interface Props extends WithStyles<typeof styles>, ConnectedProps<typeof connector> {
    
}

const SiteBody: FunctionComponent<Props> = (props: Props) => {
    const theme = useTheme();
    const [showExitIntent, setShowExitIntent] = useState(false);

    useEffect(() => {
        document.addEventListener('mouseleave', exitListener);
    }, []);

    const exitListener = (e: Event) => {
        exitIntent(e.target);
    }
   
    const exitIntent = (target: any) => {
        setShowExitIntent(true);
        document.removeEventListener('mouseleave', exitListener);
    }

    return (
        <div className={props.classes.AppWrapper}>
            <NavBar />
            <div className={props.classes.NavBarSpace} />
            <div style={{overflowX: 'hidden'}}>
                <Switch>
                    <Route path={Routes.PRESSKIT} component={Presskit} />
                    <Route path={Routes.THE_TEAM} component={AboutUs} />
                    <Route path={Routes.BLOG + "/:filter"} component={BlogPost} />
                    <Route path={Routes.BLOG} component={Blog} />
                    <Route path={Routes.HORDEBREAK} component={Hordebreak} />

                    <Route path="/" component={Home}/>
                </Switch>
            </div>
            <Footer />
            <div className={props.classes.copyrightWrapper} >
                <div className={props.classes.copyright}>
                    © Far North Entertainment, All Rights Reserved.
                </div>
            </div>
            {false && (
                <div className={props.classes.exitIntentWrapper}>
                    <div className={props.classes.exitIntentPopup}>
                        <div className={props.classes.exitIntentCloseAnchor}>
                            <div className={props.classes.exitIntentClose} onClick={() => setShowExitIntent(false)}>
                                X
                            </div>
                        </div>
                        <div className={props.classes.exitIntentHeader}>
                            <Typography variant="h3">Early backers get a <b>25%</b> product key discount!</Typography>
                        </div>
                        <div className={props.classes.exitIntentMainMessage}>
                            <Typography variant="body1">We would greatly appreciate having you with us! Care to join in?</Typography>
                        </div>
                        <Discord />
                        <div className={props.classes.exitIntentMailListWrapper}>
                            <MailSignup small />  
                        </div>
                    </div>
                </div>
            )}
            
        </div>
    )
}

export default withStyles(styles)(connector(SiteBody))