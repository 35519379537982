import React, { FunctionComponent } from 'react';
import {
  WithStyles,
  withStyles,
  useTheme,
  Typography,
} from '@material-ui/core';
import { styles } from './ImageLinkStyles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

interface Props extends WithStyles<typeof styles> {
  title: string;
  src: string;
  thumbnail?: string;
  backgroundColor?: string;
}

export const ImageLink: FunctionComponent<Props> = (props) => {
  const theme = useTheme();

  const optionalProps = () => {
    const toReturn: any = {};

    if (props.backgroundColor) {
      toReturn['style'] = { backgroundColor: props.backgroundColor };
    }

    return toReturn;
  };

  return (
    <div>
      <div className={props.classes.imgTitle}>
        <Typography variant="h1">{props.title}</Typography>
      </div>
      <div className={props.classes.imgParent}>
        <a href={props.src} target="_blank">
          <img
            src={props.thumbnail ? props.thumbnail : props.src}
            className={props.classes.img}
            {...optionalProps()}
          />
        </a>
      </div>
    </div>
  );
};

export default withStyles(styles)(ImageLink);
