import React, { FunctionComponent } from 'react';
import { WithStyles, withStyles, useTheme, Typography } from '@material-ui/core';
import { styles } from './ContentTitleStyle';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

interface Props extends WithStyles<typeof styles> {
    title: string;
    marginTop?: number;
}

export const ContentTitle: FunctionComponent<Props>  = (props) => {
    const theme = useTheme();

    return <>
        <div className={props.classes.TitleText}>
            <Typography variant="h2">{props.title}</Typography>
        </div>
        <div className={props.classes.DividerLine} />
    </>
}

export default withStyles(styles)(ContentTitle);