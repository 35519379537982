import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../Redux/RootReduxer';
import { NavLink } from 'react-router-dom';
import {styles} from './NavBarStyles';
import { withStyles, WithStyles, useTheme, Switch, IconButton } from '@material-ui/core';
import NavBarButton from './SubComponents/NavBarButton';
import { Routes } from '../../Constants/Routes';
import classes from '*.module.css';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import NavBarBurger from './SubComponents/NavBarBurger';
import { mobileQuery } from '../../Constants/MediaQuery';
import {toggleTheme} from '../Redux/MainActions';

const logoLight = require('../../Media/black.png');
const logoDark = require('../../Media/white.png');

const mapState = (state: RootState) => ({
    isDarkTheme: state.Main.isDarkTheme
})

const mapDispatch = {
    toggleTheme: () => toggleTheme()
}

const connector = connect(mapState, mapDispatch)

interface Props extends WithStyles<typeof styles>, ConnectedProps<typeof connector> {
    
}

interface Link{
    title: string;
    path: string;
}

export const Links: Array<Link> = [
    {
        title: "Home",
        path: '/'
    },
    {
        title: "Hordebreak",
        path: Routes.HORDEBREAK
    },
    {
        title: "The Team",
        path: Routes.THE_TEAM
    },
    {
        title: "Blog",
        path: Routes.BLOG
    },
    {
        title: "Presskit",
        path: Routes.PRESSKIT
    }
]

const NavBar = (props: Props) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(mobileQuery(theme));

    return <div className={props.classes.bar}>
        <NavLink
            exact
            to={Routes.HOME}
            className={props.classes.buttonText}
            activeClassName={props.classes.buttonText}
        >
            <IconButton 
                className={props.classes.logoWrapper} 
                size="small"
            >
                <img 
                    src={props.isDarkTheme ? logoDark : logoLight} 
                    className={props.classes.logo}
                />
            </IconButton>
        </NavLink>
        
        
        <div className={props.classes.buttonWrapper}>
            {!isMobile ? 
                <>
                    {Links.map((linkData) =>  
                        <NavBarButton 
                            key={linkData.path}
                            {...linkData}
                        />
                    )}
                </>
            : 
                <NavBarBurger />
            }
             <Switch checked={props.isDarkTheme} onClick={props.toggleTheme}></Switch>
        </div>
    </div>
    
}



export default withStyles(styles)(connector(NavBar));