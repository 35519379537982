import React, { FunctionComponent } from 'react';
import {
  WithStyles,
  withStyles,
  useTheme,
  Typography,
} from '@material-ui/core';
import { styles } from './DiscordStyles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { RootState } from '../../../Redux/RootReduxer';
import { connect, ConnectedProps } from 'react-redux';

const mapState = (state: RootState) => ({
    isDarkTheme: state.Main.isDarkTheme
})

const mapDispatch = {
    
}

const connector = connect(mapState, mapDispatch)

interface Props extends WithStyles<typeof styles>, ConnectedProps<typeof connector> {}

const disc = require('../../../Media/DiscordLogo.png');
const discDark = require('../../../Media/DiscordLogoDark.png');


const Discord: FunctionComponent<Props> = (props) => {
  const theme = useTheme();

  return (
    <div className={props.classes.DiscordWrapper}>
        <a href="https://discord.gg/jXJyxUh"><img src={props.isDarkTheme ? disc : discDark} className={props.classes.DiscordImage}/></a>
    </div>
  );
};

export default withStyles(styles)(connector(Discord));
