import React, { useState, useEffect, FunctionComponent } from 'react'
import { styles } from './styles';
import { withStyles, Typography, Button, TextField, Input, Checkbox, makeStyles } from '@material-ui/core';
import { WithStyles } from '@material-ui/styles';
import { CheckBox } from '@material-ui/icons';
import MailchimpSubscribe from "react-mailchimp-subscribe"
import { regExpLiteral } from '@babel/types';

interface Props extends WithStyles<typeof styles> {
    small?: boolean;
}
interface FormProps  extends Props {
    status: any,
    message: any,
    onValidated: any
}
const CForm: FunctionComponent<FormProps> = (props) => {
    const classes = props.classes;

    const onValidated = props.onValidated;
    const status = props.status;
    const message = props.message;

    const [gdpr, setGdpr] = useState(false);
    const [error, setError] = useState('');
    const [mail, setMail] = useState('');

    let email: any, name: any;
    const submit = () =>
        email &&
        email.value.indexOf("@") > -1 &&
        onValidated({
            EMAIL: email.value
        });

    const validateMail = () => {
        const regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        return regexp.test(mail);
    }

    return (<div className={classes.form}>
        <div className={classes.inputWrapper}>
            <div className={classes.inputWrapperInner}>
                <div className={classes.inputButtonWrapper}>
                    <input
                        className={classes.inputField}
                        ref={node => (email = node)}
                        type="email"
                        placeholder="Your email"
                        id="mce-EMAIL" 
                        onChange={(event: any) => {
                            setMail(event.target.value);
                        }}
                    />
                    <Button
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        type="submit"
                        onClick={() => {
                            setError('');
                            if(!gdpr){
                                console.log("IS ERROR");
                                setError('Please agree to the terms before signing up');
                                return;
                            }
                            if(!validateMail()){
                                console.log("INVALID MAIL");
                                setError('Please provide a valid email adress');
                            }
                            submit();
                        }}
                        id="submitButton" 
                    >
                        {'SIGN UP'}
                    </Button>
                </div>
                <div className={classes.mailchimp}>
                    <Typography variant="body2">We use Mailchimp as our marketing platform. By subscribing, you acknowledge that your information will be transferred to Mailchimp for processing. <a href="https://mailchimp.com/legal/" target="_blank">Learn more about Mailchimp's privacy practices here</a></Typography>                
                </div>
                <div className={classes.gdprWrapper}>
                    <Checkbox 
                        checked={gdpr}
                        name="GDPR" 
                        id="mce-GDPR" 
                        onChange={() => {
                            setError('');
                            setGdpr(!gdpr);
                        }} 
                        required
                        className={classes.checkbox}
                    />
                    <div>
                        <Typography variant="body1">I agree to Mailchimp's terms, as well as Far North Entertainment's <a href="https://www.farnorthentertainment.com/pp" target="_blank">privacy policy</a></Typography>
                    </div>
                </div>
            </div>
        </div>
        <div 
            style={{position: 'absolute', left: '-5000px'}} 
            aria-hidden="true"
        >
            <input 
                type="text" 
                name="b_e24c9295d0ff31b10792e86a0_8112d8f509" 
            />
        </div>
        <div style={{height: 60}}>
            <div className={status !== 'success' && status !== 'error' && error === '' ? classes.message : classes.messageActive}>
                {status === "sending" && 
                    <div style={{ color: "blue" }}>sending...</div>
                }
                {status === "error" && (
                    <div
                        className={classes.errorWindow}
                        dangerouslySetInnerHTML={{ __html: message }}
                    />
                )}
                {error !== "" && (
                    <div
                        className={classes.errorWindow}
                        dangerouslySetInnerHTML={{ __html: error }}
                    />
                )}
                {status === "success" && (
                <div
                    className={classes.successWindow}
                    dangerouslySetInnerHTML={{ __html: message }}
                />
                )}
            </div>
        </div>
    </div>);
  };

const CustomForm = withStyles(styles)(CForm);

const MailSignup = withStyles(styles)((props: Props) => {
    const classes = props.classes;

    const [email, setEmail] = useState('');
    const [gdpr, setGdpr] = useState(false);

    const url = "https://farnorthentertainment.us16.list-manage.com/subscribe/post?u=e24c9295d0ff31b10792e86a0&amp;id=8112d8f509";

    return <div className={classes.root}>
        {!props.small && <div className={classes.titleWrapper}>
            <Typography variant="h2">MAIL LIST SIGNUP!</Typography>
        </div>}
        {!props.small && <div className={classes.description}>
            <Typography variant="body1">Sign up to our newsletter!</Typography>
        </div>}
        <MailchimpSubscribe
          url={url}
          render={({ subscribe, status, message }: any) => (
            <CustomForm
              status={status}
              message={message}
              onValidated={(formData: any) => subscribe(formData)}
              small={props.small}
            />
          )}
        />
    </div>
});

export default MailSignup;