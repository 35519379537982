import { createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { mobileQuery } from '../../Constants/MediaQuery';
import { FullscreenExit } from '@material-ui/icons';

export const styles = (theme: Theme) => {
    return createStyles({
        cardWrapper: {
            display: 'flex',
            margin: 'auto',
            width: 460,
            height: 160,
            marginBottom: 16,
            transition: '0.3s',
            [mobileQuery(theme)]: {
                width: '100vw',
                height: '30vw'
            }
        },
        cardPortrait: {
            width: 160,
            height: 160,
            [mobileQuery(theme)]: {
                width: '30vw',
                height: '30vw'
            }
        },
        cardPortraitAction:{
            width: 160,
            height: 160,
            opacity: 0,
            transition: '0.5s',
            '&:hover': {
                opacity: 1
            },
            [mobileQuery(theme)]: {
                width: '30vw',
                height: '30vw'
            }
        },
        cardInfo: {
            backgroundColor: theme.fne_palette.paper,
            width: 300,
            height: 128,
            color: theme.palette.text.primary,
            padding: 20,
            paddingTop: 12,
            display: 'flex',
            flexDirection: 'column',
            [mobileQuery(theme)]: {
                width: '60vw',
                padding: '4vw',
                height: '22vw',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center'
            }
        },
        deckWrapper: {
            display: 'flex',
            margin: 'auto',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignContent: 'center',
            alignItems: 'space-between',
            flexWrap: 'wrap',
            transition: '0.3s',
            marginTop: 20,
            [mobileQuery(theme)]: {
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
            }
        },
        quoteWrapper: {
            display: 'flex',
            flex: 1,
            marginTop:8,
            transition: '0.3s',
            alignItems: 'center',
            justifyContent: 'center'
        },
        quoteParent: {
            maxWidth: 220,
            textAlign: 'center',
            color: theme.palette.text.primary,
            opacity: 0.5,
            fontFamily: 'Roboto',
            fontWeight: 300,
            transition: '0.3s'
        },
        teamMain: {
            display: 'flex',
            margin: 'auto',
            maxWidth: 970,
            overflowX: 'visible',
            transition: '0.3s',
            paddingTop: 10,
            flexDirection: 'column',
            alignItems: 'center'
        },
        teamInfo: {
            maxWidth: 700,
            paddingLeft: 40,
            paddingRight: 40,
            color: theme.palette.text.primary,
            transition: '0.3s'
        }
    })
};