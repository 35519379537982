import { createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { mobileQuery } from '../../../Constants/MediaQuery';

export const styles = (theme: Theme) => {
  return createStyles({
    root: {
      maxWidth: "75vw",
      display: 'flex',
      margin: 'auto',
      alignItems: 'center',
      flexDirection: 'column',
      [mobileQuery(theme)]: {
        maxWidth: '90vw',
      }
    },
    titleWrapper: {
      textIndent: 4,
      fontWeight: 500,
      fontFamily: 'Roboto',
      color: theme.fne_palette.fne_red,
      fontSize: 32,
      textAlign: 'center',
      letterSpacing: 2,
      [mobileQuery(theme)]: {
        fontSize: 24,
        maxWidth: '90vw',
      }
    },
    description: {
      fontFamily: 'Roboto',
      maxWidth: '75%',
      color: theme.palette.text.primary,
      fontSize: 16,
      textAlign: 'center',
      [mobileQuery(theme)]: {
        fontFamily: 'Calibri',
        maxWidth: '90vw',
        fontSize: 16,
      }
    },
    signupWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      width: '100%',
      marginTop: 24,
    },
    inputField: {
      padding: 12,
      paddingTop: 6,
      paddingBottom: 6,
      fontSize: 14,
      fontFamily: 'Roboto',
      width: 400,
      backgroundColor: theme.fne_palette.background,
      border: 'solid',
      borderWidth: 2,
      borderColor: '#ccc',
      borderRadius: 3,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      color: 'white',
      '&:focus': {
        borderColor: theme.fne_palette.barBackground,
        outline: 'none !important'
      },
      [mobileQuery(theme)]: {
        fontSize: 12,
        borderWidth: 1,
        height: 20,
        paddingLeft: 10,
        width: 240,
      }
    },
    button: {
      borderRadius: 30,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      paddingLeft: 15,
      paddingRight: 15,
      fontSize: 15,
      color: 'white',
      marginLeft: 20,
      lineHeight: 1.2,
      '&:disabled': {
        backgroundColor: theme.fne_palette.paper,
        opacity: 0.2
      },
      [mobileQuery(theme)]: {
        fontSize: 10,
        marginLeft: 10,
        height: 34,
      }
    },
    gdprWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      color: theme.palette.text.primary,
      maxWidth: '70vw',
      marginTop: 10,
      '& a': {
        marginLeft: 0,
        color: theme.fne_palette.fne_red
      },
      [mobileQuery(theme)]: {
        width: '90vw',
        maxWidth: '90vw',
      }
    },
    form: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: 20,
      maxWidth: '70vw',
      [mobileQuery(theme)]: {
        width: '90vw',
        maxWidth: '90vw',
      }
    },
    inputWrapper: {
      flex: 1,
      display: 'flex',
      maxWidth: '70vw',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      [mobileQuery(theme)]: {
        width: '90vw',
        maxWidth: '90vw',
      }
    },
    inputWrapperInner: {
      flex: 1,
      maxWidth: 800,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      [mobileQuery(theme)]: {
        
      }
    },
    inputButtonWrapper: {
      display: 'flex',
      margin: 'auto',
      maxWidth: '70vw',
      justifyContent: 'center',
      alignItms: 'center',
      [mobileQuery(theme)]: {
        width: '90vw',
        maxWidth: '90vw',
      }
    },
    checkbox: {
      color: 'white',
      [mobileQuery(theme)]: {
        
      }
    },
    mailchimp: {
      maxWidth: '70%',
      marginTop: 20,
      color: theme.palette.text.primary,
      '& a': {
        marginLeft: 4,
        color: theme.fne_palette.fne_red
      },
      [mobileQuery(theme)]: {
        width: '90vw',
        maxWidth: '90vw',
      }
    },
    message: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontFamily: 'Calibri',
      color: '#ccc',
      marginTop: 10,
      maxHeight: 0,
      overflow: 'hidden',
      [mobileQuery(theme)]: {
        
      }
    },
    messageActive: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontFamily: 'Calibri',
      color: '#ccc',
      marginTop: 10,
      maxHeight: 60,
      transition: 'max-height .3s ease-in .3s',
      overflow: 'hidden',
      [mobileQuery(theme)]: {
        
      }
    },
    successWindow: {
      backgroundColor: '#226622',
      borderRadius: 8,
      padding: 8,
      paddingLeft: 20,
      paddingRight: 20,
      [mobileQuery(theme)]: {
        
      }
    },
    errorWindow: {
      backgroundColor: theme.fne_palette.paper,
      borderRadius: 3,
      padding: 8,
      paddingLeft: 20,
      paddingRight: 20,
      [mobileQuery(theme)]: {
        
      }
    },
  });
};