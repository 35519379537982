import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {MainState} from './Redux/MainReducer';
import { RootState } from '../Redux/RootReduxer';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import NavBar from './NavBar/NavBar';
import Home from '../Sections/Home/Home';
import AboutUs from '../Sections/TheTeam/TheTeam';
import { ThemeProvider, WithStyles, withStyles } from '@material-ui/core';
import {DarkTheme, LightTheme} from '../Theme/Themes';
import { Routes } from '../Constants/Routes';
import SiteBody from './SiteBody/SiteBody';

const mapState = (state: RootState) => ({
    isDarkTheme: state.Main.isDarkTheme
})

const mapDispatch = {

}

const connector = connect(mapState, mapDispatch)

interface Props extends ConnectedProps<typeof connector> {
    
}

const Main = (props: Props) => (
    <ThemeProvider theme={props.isDarkTheme ? DarkTheme() : LightTheme()}>
        <Router>
            <SiteBody />
        </Router>
    </ThemeProvider>
)

export default connector(Main);