import React from 'react';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import {RootReducer} from './Redux/RootReduxer';
import Main from './Main/Main';
import 'typeface-roboto';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';


const store = createStore(RootReducer);

function App() {
  return (
    <Provider store={store}>
        <Main />
    </Provider>
  );
}

export default App;
