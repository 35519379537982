import { 
    MainActionTypes, 
    TOGGLE_THEME 
} from './MainActionTypes';

export interface MainState {
    stateValue: string,
    isDarkTheme: boolean
}

const initState: MainState = {
    stateValue: "StartValue",
    isDarkTheme: true
};

const MainReducer = (
    state = initState, 
    action: MainActionTypes
) => {
  switch (action.type) {
    case TOGGLE_THEME:
      return {
          ...state,
          isDarkTheme: !state.isDarkTheme
      } 
    default:
      return state
  }
}

export default MainReducer;