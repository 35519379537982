import createMuiTheme, {
  Theme,
  ThemeOptions,
} from '@material-ui/core/styles/createMuiTheme';

import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import {
  Typography,
  TypographyStyle,
} from '@material-ui/core/styles/createTypography';
import { mobileQueryTheme } from '../Constants/MediaQuery';
import { SportsRugbySharp } from '@material-ui/icons';

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    appDrawer: {
      width: React.CSSProperties['width'];
      breakpoint: Breakpoint;
    };
    fne_palette: {
      background: React.CSSProperties['color'];
      backgroundTransparent: React.CSSProperties['color'];
      backgroundTransparent2: React.CSSProperties['color'];
      barBackground: React.CSSProperties['color'];
      paper: React.CSSProperties['color'];
      fne_red: '#c52e2c';
    };
  }

  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    appDrawer?: {
      width?: React.CSSProperties['width'];
      breakpoint?: Breakpoint;
    };
    fne_palette: {
      background: React.CSSProperties['color'];
      backgroundTransparent: React.CSSProperties['color'];
      backgroundTransparent2: React.CSSProperties['color'];
      barBackground: React.CSSProperties['color'];
      paper: React.CSSProperties['color'];
      fne_red: React.CSSProperties['color'];
    };
  }
}

export const LightTheme = function createMyTheme() {
  return createMuiTheme({
    appDrawer: {
      width: 225,
      breakpoint: 'lg',
    },
    palette: {
      text: {
        primary: '#151515',
        secondary: '#555555',
      },
      background: {
        default: '#f0f',
        paper: '#fff',
      },
      secondary: {
        main: '#c52e2c',
        dark: '#c52e2c',
        light: '#c52e2c',
        contrastText: '#c52e2c',
      },
    },
    typography: {
      // NAVBAR LINK
      h1: {
        fontSize: '18px',
        fontFamily: 'Roboto',
      },
      // Content Title
      h2: {
        fontSize: '40px',
        fontFamily: 'Calibri',
        textTransform: 'capitalize',
        [mobileQueryTheme()]: {
          fontSize: '26px',
        },
      },
      // Content Section Title
      h3: {
        fontSize: '24px',
        fontFamily: 'Roboto',
        textTransform: 'capitalize',
        fontWeight: 50,
        marginBottom: 16,
        [mobileQueryTheme()]: {
          fontSize: '22px',
        },
      },
      // Person Card Title
      h4: {
        fontSize: '22px',
        fontFamily: 'Roboto',
        textTransform: 'capitalize',
        fontWeight: 50,
        marginBottom: 4,
        [mobileQueryTheme()]: {
          fontSize: '18px',
        },
      },
      h5: {
        fontSize: '16px',
        fontFamily: 'Roboto',
        textTransform: 'capitalize',
        fontWeight: 400,
        marginBottom: 4,
        [mobileQueryTheme()]: {
          fontSize: '12px',
        },
      },
      body1: {
        fontSize: '14px',
        fontFamily: 'Roboto',
        fontWeight: 300,
      },
      body2: {
        fontSize: '12px',
        fontFamily: 'Roboto',
        fontWeight: 300,
      },
    },
    fne_palette: {
      background: '#dfdfdf',
      backgroundTransparent: 'rgba(220, 220, 220, 0.75)',
      backgroundTransparent2: 'rgba(0, 0, 0, 0.15)',
      barBackground: '#eeeeee',
      paper: '#fff',
      fne_red: '#c52e2c',
    },
  });
};

export const DarkTheme = function createMyTheme() {
  return createMuiTheme({
    appDrawer: {
      width: 225,
      breakpoint: 'lg',
    },
    palette: {
      text: {
        primary: '#c7c7c7',
        secondary: '#b7b7b7',
      },
      background: {
        default: '#f0f',
        paper: '#151515',
      },
      secondary: {
        main: '#c52e2c',
        dark: '#c52e2c',
        light: '#c52e2c',
        contrastText: '#c52e2c',
      },
    },
    typography: {
      // NAVBAR LINK
      h1: {
        fontSize: '18px',
        fontFamily: 'Roboto',
      },
      // Content Title
      h2: {
        fontSize: '40px',
        fontFamily: 'Calibri',
        textTransform: 'capitalize',
        [mobileQueryTheme()]: {
          fontSize: '26px',
        },
      },
      // Content Section Title
      h3: {
        fontSize: '24px',
        fontFamily: 'Roboto',
        textTransform: 'capitalize',
        fontWeight: 50,
        marginBottom: 16,
        [mobileQueryTheme()]: {
          fontSize: '22px',
        },
      },
      // Person Card Title
      h4: {
        fontSize: '24px',
        fontFamily: 'Roboto',
        textTransform: 'capitalize',
        fontWeight: 50,
        marginBottom: 4,
        [mobileQueryTheme()]: {
          fontSize: '22px',
        },
      },
      h5: {
        fontSize: '16px',
        fontFamily: 'Roboto',
        textTransform: 'capitalize',
        fontWeight: 400,
        marginBottom: 4,
        [mobileQueryTheme()]: {
          fontSize: '12px',
        },
      },
      body1: {
        fontSize: '14px',
        fontFamily: 'Roboto',
        fontWeight: 300,
      },
      body2: {
        fontSize: '12px',
        fontFamily: 'Roboto',
        fontWeight: 300,
      },
    },
    fne_palette: {
      background: '#0f0f0f',
      barBackground: '#151515',
      backgroundTransparent: 'rgba(0.059, 0.059, 0.059, 0.75)',
      backgroundTransparent2: 'rgba(255, 255, 255, 0.15)',
      paper: '#151515',
      fne_red: '#c52e2c',
    },
  });
};
