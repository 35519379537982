import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../Redux/RootReduxer';
import { WithStyles, withStyles, Typography, useTheme, useMediaQuery } from '@material-ui/core';
import { styles } from './BlogStyles';
import { mobileQuery } from '../../Constants/MediaQuery';
import { Link } from 'react-router-dom';

const mapState = (state: RootState) => ({
    isDarkMode: state.Main.isDarkTheme
})

const mapDispatch = {
    
}

const connector = connect(mapState, mapDispatch)

interface Props extends WithStyles<typeof styles>, ConnectedProps<typeof connector>{

}

interface PostList {
    posts: PostListEntry[];
}

interface PostListEntry {
        title: string;
        folder: string;
        date: string;
        imagePath: string;
}

const Blog = (props : Props) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(mobileQuery(theme));

    const [json, setJson] = useState<PostList | undefined>(undefined);

    useEffect(() => {fetch("/blogposts/postList.json"
        ,{
        headers : { 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
        }
    ).then(function(response){
        return response.json();
    }).then(function(myJson) {
        setJson(myJson);
    })}, [])

    return <div className={props.classes.blogMain}>
        {json ? (json as any).posts.map((e: PostListEntry, i: number) => {
            return  <Link to={"/Blog/"+e.folder} className={props.classes.postEntryLink}>
                        <div className={props.classes.postEntry}>
                            {e.imagePath ? 
                                <img src={"/blogposts/" + e.folder + "/" + e.imagePath } style={{ width: 120, height: 120, marginRight: 10 }} /> 
                                : <img src={props.isDarkMode ? "/logolight.png" : "/logodark.png"} style={{ width: 120, height: 120, marginRight: 10 }} />}
                            <div style={{ flex: 2 }}>
                                <Typography variant="h2" style={{ textTransform: 'none' }}>
                                    {e.title}
                                </Typography>
                                <Typography variant="body1">
                                    {e.date}
                                </Typography>
                            </div>
                        </div>
                    </Link>;
        }) : null}
    </div>
    
}

export default withStyles(styles)(connector(Blog));