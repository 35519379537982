import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../Redux/RootReduxer';
import { WithStyles, withStyles, Typography, useTheme, useMediaQuery } from '@material-ui/core';
import { styles } from './TheTeamStyles';
import PanoramaView from '../Components/PanoramaView/PanoramaView';
import { mobileQuery } from '../../Constants/MediaQuery';

const rw_idle = require('../../Media/rw_idle.jpg');
const rw_action = require('../../Media/rw_action.jpg');
const kb_idle = require('../../Media/kb_idle.jpg');
const kb_action = require('../../Media/kb_action.jpg');
const ae_idle = require('../../Media/ae_idle.jpg');
const ae_action = require('../../Media/ae_action.jpg');
const jm_idle = require('../../Media/jm_idle.jpg');
const jm_action = require('../../Media/jm_action.jpg');
const se_idle = require('../../Media/simon_idle.jpg');
const se_action = require('../../Media/simon_action.jpg');

const mapState = (state: RootState) => ({
    isDarkMode: state.Main.isDarkTheme
})

const mapDispatch = {
    
}

const connector = connect(mapState, mapDispatch)

interface Props extends WithStyles<typeof styles>, ConnectedProps<typeof connector>{

}

interface PersonCardProps extends WithStyles<typeof styles>{
    name: string;
    title: string;
    idleIMG: string;
    actionIMG: string;
    quote: string;
    index: number;
}

const teamBannerDark = require('../../Media/teambannerdark.png');
const teamBannerLight = require('../../Media/teambannerlight.png');

const teamData = [
    {
        name: 'Jimmy Eliasson Malmer',
        title: 'CEO & Business Developer',
        idleIMG: jm_idle,
        actionIMG: jm_action,
        quote: "Every board meeting needs at least one meme."
    },
    {
        name: 'Anders Eriksson',
        title: 'CTO & Lead Programmer',
        idleIMG: ae_idle,
        actionIMG: ae_action,
        quote: "The sum of all positive integers up to infinity will converge to -1/12."
    },
    {
        name: 'Simon Eliasson',
        title: 'Game Director & Project Manager',
        idleIMG: se_idle,
        actionIMG: se_action,
        quote: "Every game needs cool hats!"
    },
    {
        name: 'Kevin Björklund',
        title: 'Art Director & Environment Artist',
        idleIMG: kb_idle,
        actionIMG: kb_action,
        quote: "My hat gives me +30 Strength."
    },
    {
        name: 'Richard Wecke',
        title: 'Character Artist & Animator',
        idleIMG: rw_idle,
        actionIMG: rw_action,
        quote: "I empathize with every game that is forced to have \'cool\' hats."
    },
] as Array<PersonCardProps>;



const TheTeam = (props: Props) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(mobileQuery(theme));

    const PersonCard = (props: PersonCardProps) => (<div className={props.classes.cardWrapper}>
        {props.index % 2 == 0 ?
            <div 
                style={{
                    backgroundImage: "url(" + props.idleIMG + ")",
                    backgroundSize: 'cover'
                }}
                className={props.classes.cardPortrait}
            >
                <div 
                    className={props.classes.cardPortraitAction}
                    style={{
                        backgroundImage: "url(" + props.actionIMG + ")",
                        backgroundSize: 'cover'
                    }}
                >
                
                </div>
            </div>
        :
            <div className={props.classes.cardInfo}>
                <Typography variant="h4">{props.name}</Typography>
                <Typography variant="body1">{props.title}</Typography>
                {!isMobile && <div className={props.classes.quoteWrapper}>
                    <div className={props.classes.quoteParent}>
                        <i>"{props.quote}"</i>
                    </div>
                </div>}
            </div>
        }
        {props.index % 2 == 1 ?
            <div 
                style={{
                    backgroundImage: "url(" + props.idleIMG + ")",
                    backgroundSize: 'cover'
                }}
                className={props.classes.cardPortrait}
            >
                <div 
                    className={props.classes.cardPortraitAction}
                    style={{
                        backgroundImage: "url(" + props.actionIMG + ")",
                        backgroundSize: 'cover'
                    }}
                >
                
                </div>
            </div>
        :
            <div className={props.classes.cardInfo}>
                <Typography variant="h4">{props.name}</Typography>
                <Typography variant="body1">{props.title}</Typography>
                {!isMobile && <div className={props.classes.quoteWrapper}>
                    <div className={props.classes.quoteParent}>
                        <i>"{props.quote}"</i>
                    </div>
                </div>}
            </div>
        }
    </div>);

    return <div className={props.classes.teamMain}>
        <PanoramaView
            heightInVh={20}
            parallax
            backgroundImage={props.isDarkMode ? teamBannerDark : teamBannerLight}
        >
            <div className={props.classes.teamInfo}>
                <Typography variant="body1">
                    Founded in 2016, Far North Entertainment began as a bachelor exam project at Chalmers University of Technology. 
                    Realising that creating games were their ultimate destiny, the students turned said project into the company's first commercial product.
                    <br />
                    <br />
                    We all have one thing in common: An unshakable passion for IT technology in general
                    and game development in particular. On our journey together, we discovered that even a small team can achieve truly great things. 
                </Typography>
            </div>
        </PanoramaView>
        <div className={props.classes.deckWrapper}>
            {teamData.map((td, index) => 
                <PersonCard 
                    classes={props.classes}
                    {...td}
                    index={index}
                />    
            )}
        </div>
    </div>
    
}

export default withStyles(styles)(connector(TheTeam));