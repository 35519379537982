import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../Redux/RootReduxer';
import {
  WithStyles,
  withStyles,
  Typography,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import PanoramaView from '../Components/PanoramaView/PanoramaView';
import { styles } from './PresskitStyles';
import { mobileQuery } from '../../Constants/MediaQuery';
import ContentTitle from '../Components/ContentTitle/ContentTitle';
import { NavLink, Route, Switch, useRouteMatch } from 'react-router-dom';
import { Routes } from '../../Constants/Routes';
import PresskitMain from './SubSections/PresskitMain';
import PresskitProjectFNZ from './SubSections/PresskitProjectFNZ';

const mapState = (state: RootState) => ({
  isDarkMode: state.Main.isDarkTheme,
});

const mapDispatch = {};

const connector = connect(mapState, mapDispatch);

interface Props
  extends WithStyles<typeof styles>,
    ConnectedProps<typeof connector> {}

const teamBannerDark = require('../../Media/teambannerdark.png');
const teamBannerLight = require('../../Media/teambannerlight.png');

const Presskit = (props: Props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(mobileQuery(theme));

  let match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/Hordebreak`}>
        <PresskitProjectFNZ />
      </Route>
      <Route path={match.path}>
        <PresskitMain />
      </Route>
    </Switch>
  );
};

export default withStyles(styles)(connector(Presskit));
