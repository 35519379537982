import { createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { mobileQuery } from '../../../Constants/MediaQuery';

export const styles = (theme: Theme) => {
  return createStyles({
    DiscordWrapper: {
      width: '50%',
      backgroundColor: theme.fne_palette.backgroundTransparent,
      borderColor: theme.fne_palette.backgroundTransparent2,
      borderWidth: 2,
      borderStyle: 'solid',
      paddingLeft: 32,
      paddingRight: 32,
      borderRadius: 32,
      alignSelf: 'center',
      marginTop: 16,
      cursor: 'pointer'
    },
    DiscordImage: {
        width: '100%',
    },
  });
};
