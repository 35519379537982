import { createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { mobileQuery } from '../../../Constants/MediaQuery';

export const styles = (theme: Theme) => {
    return createStyles({
        panoramaMain: {
            display: 'flex',
            width: '100%',
            transition: 'background-color 0.3s',
            paddingBottom: 20,
            paddingTop: 20,
            alignItems: 'center',
            justifyContent: 'center',
            [mobileQuery(theme)]:{
                minHeight: '30vh',
            }
        },
        panoramaContentWrapper: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            margin: 'auto',
        }
    })
};